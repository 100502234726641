import React from 'react'
import PropTypes from 'prop-types'

import dayjs from 'dayjs'
import 'dayjs/locale/de'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat).locale('de')

const ListItem = (props) => {
  const { event } = props
  const date = event.acf.date ? event.acf.date : null
  // const dateEnd = event.acf['date-end'] ? event.acf['date-end'] : null
  const locality = event.acf.locality ? event.acf.locality : null
  const location = event.acf.location ? event.acf.location : null
  const hasLocationLink = event.acf['location-link'] !== ''
  const externalLink = event.acf['external-link'] ? event.acf['external-link'] : false

  return (
    <div className='event'>
      {event.acf['member-bonus'] ? (
        <div className='member-moeppel'>
          <span>
            <span className='member-moeppel__icon' />
            <span className='member-moeppel__text'>
              Member-
              <br />
              Bonus
            </span>
          </span>
        </div>
      ) : null}
      {event.acf.image ? (
        <img
          width='350'
          height='250'
          alt={event.title.rendered}
          src={event.acf.image.sizes.thumbnail}
        />
      ) : null}
      <h4 className='event__title'>{event.title.rendered}</h4>
      <div className='event__details'>
        <span className='details__date'>{dayjs(date, 'YYYY-MM-DD').format('DD.MM.YYYY')}</span>
        <span className='details__locality'>{locality}</span>
        <span className='details__location'>
          {hasLocationLink ? (
            <a
              href={event.acf['location-link'].url}
              target={event.acf['location-link'].target}
              title={event.acf['location-link'].title}>
              {location}
            </a>
          ) : (
            location
          )}
        </span>
        {event.acf['event-type'] === 'details' ? (
          <a className='event-link' href={event.link}>
            weitere Infos
          </a>
        ) : null}
        {externalLink ? (
          <a
            className='event-link'
            href={externalLink.url}
            target={externalLink.target}
            title={externalLink.title}>
            {externalLink.title}
          </a>
        ) : null}{' '}
      </div>
    </div>
  )
}

ListItem.propTypes = {
  event: PropTypes.object.isRequired
}

export default ListItem

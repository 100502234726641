import React from 'react'
import PropTypes from 'prop-types'

const ListItem = (props) => {
  const { partner } = props
  const description = partner.acf.description ? partner.acf.description : ''
  return (
    <div className='partner'>
      {partner.acf.image ? (
        <img
          width='480'
          height='342'
          alt={partner.title.rendered}
          src={partner.acf.image.sizes['thumbnail-retina']}
        />
      ) : null}
      <h4 className='partner__title' dangerouslySetInnerHTML={{ __html: partner.title.rendered }} />
      <div className='partner__desc' dangerouslySetInnerHTML={{ __html: description }} />
      {partner.acf.link.url ? (
        <a
          className='partner__link button small'
          href={partner.acf.link.url}
          target={partner.acf.link.target}>
          {partner.acf.link.title}
        </a>
      ) : null}
    </div>
  )
}

ListItem.propTypes = {
  partner: PropTypes.object.isRequired
}

export default ListItem

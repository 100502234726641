import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'

import 'isomorphic-fetch'

import Error from './helpers/Error'
import Loader from './helpers/Loader'
import PartnerList from './components/PartnerList'

const PartnerOverview = () => {
  const [hasError, setErrors] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [partner, setPartner] = useState()
  const [partnerFilters, setPartnerFilters] = useState([])

  const loadData = () => {
    fetch('/wp-json/wp/v2/partner/?per_page=100&orderby=menu_order&order=asc')
      .then((response) => response.json())
      .then((data) => {
        setPartner(data)
        setLoading(false)
      })
      .catch((error) => setErrors(error))
  }

  const handleFilterButtonClick = useCallback(
    (event) => {
      const { target } = event
      target.classList.toggle('active')
      const filterAttribute = target.getAttribute('data-filter')
      const newEventFilter = parseInt(filterAttribute, 10)
      const indexOfFilter = partnerFilters.indexOf(newEventFilter)
      if (indexOfFilter > -1) {
        const newpartnerFilters = partnerFilters
        newpartnerFilters.splice(indexOfFilter, 1)
        setPartnerFilters([...newpartnerFilters])
      } else {
        setPartnerFilters((oldpartnerFilters) => [...oldpartnerFilters, newEventFilter])
      }
      event.preventDefault()
    },
    [partnerFilters]
  )

  useEffect(() => {
    loadData()

    const filterButtons = [...document.querySelectorAll('.event-filter-button')]

    // Add event listener
    filterButtons.forEach((button) => {
      button.addEventListener('click', handleFilterButtonClick)
    })

    return () => {
      // Remove event listener
      filterButtons.forEach((button) => {
        button.removeEventListener('click', handleFilterButtonClick)
      })
    }
  }, [handleFilterButtonClick])

  return (
    <>
      {hasError && <Error />}
      {isLoading ? <Loader /> : <PartnerList partner={partner} partnerFilters={partnerFilters} />}
    </>
  )
}

const domContainer = document.getElementById('partnerOverview')
if (domContainer) {
  ReactDOM.render(<PartnerOverview />, domContainer)
}

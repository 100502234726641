import React from 'react'
import PropTypes from 'prop-types'

import Partner from './Partner'

const PartnerList = (props) => {
  const { partner, partnerFilters } = props

  const partnersFiltered = partner.filter(function abc1(item) {
    return (
      item['partner-category'].filter(function abc2(category) {
        return partnerFilters.indexOf(category) > -1
      }).length === partnerFilters.length
    )
  })

  const partnersToDisplay = partnerFilters.length > 0 ? partnersFiltered : partner

  return (
    <>
      {partnersToDisplay.map((partnerItem) => (
        <Partner key={partnerItem.id} partner={partnerItem} className='partner' />
      ))}
    </>
  )
}

PartnerList.propTypes = {
  partner: PropTypes.array.isRequired,
  partnerFilters: PropTypes.array.isRequired
}

export default PartnerList

/* eslint-disable */
import * as PIXI from 'pixi.js'
class Hand {
  constructor(setup, textureId, index) {
    this.setup = setup
    this.setup.debugLog('new Hand')
    this.textureId = textureId
    this.index = index
    this.thumbTexture = null
    this.pointerTexture = null
    this.speedFactor = Math.random() * 0.5 + 0.6
    this.defaultDirection = Math.round(Math.random())

    this.pixiObj = new PIXI.Container()
    this.sprite = null

    this.setRotationBoundary()
    this.addPointer()
    this.addEventListeners()
  }

  addEventListeners = () => {
    window.addEventListener('resize', this.onWindowResize)
  }

  onWindowResize = () => {
    this.sprite.anchor.x = this.setup.isMobile ? 0.5 : 0.8
    this.setRotationBoundary()
    this.setScale()
    this.setPositionX()
    this.setPositionY()
  }

  setRotationBoundary = () => {
    this.rotationBoundary = this.setup.isMobile ? 360 : 60
  }

  addPointer = () => {
    this.pointerTexture = this.setup.loader.resources[this.textureId].texture.clone()

    const thumbTextureId = this.textureId.replace('Pointer', 'Thumb')
    if (thumbTextureId in this.setup.loader.resources) {
      this.thumbTexture = this.setup.loader.resources[thumbTextureId].texture.clone()
    }
    const sprite = new PIXI.Sprite(this.pointerTexture)
    this.sprite = sprite
    this.setScale()
    sprite.anchor.x = this.setup.isMobile ? 0.5 : 0.8
    sprite.anchor.y = 1

    if (this.index % 2) {
      // right side
      sprite.angle = -90
    } else {
      // left side
      sprite.scale.x *= -1
      sprite.angle = 90
    }

    this.setPositionX()
    this.setPositionY()

    this.pixiObj.addChild(sprite)
  }

  setScale = () => {
    const scaleFactor = this.setup.isMobile
      ? this.setup.vh / 26.5
      : this.setup.BS / (Math.random() * 0.3 + 2.2)
    this.sprite.scale.x = this.sprite.scale.x > 0 ? scaleFactor : scaleFactor * -1
    this.sprite.scale.y = this.sprite.scale.y > 0 ? scaleFactor : scaleFactor * -1
  }

  setPositionX = () => {
    if (!this.setup.isMobile) {
      if (this.index % 2) {
        // right side
        this.sprite.position.x =
          this.setup.renderer.width / this.setup.renderer.resolution + this.sprite.height / 8
      } else {
        // left side
        this.sprite.position.x = this.sprite.height / -8
      }
    } else {
      // if is mobile device
      this.index !== 1
        ? (this.sprite.position.x = this.sprite.height * this.setup.renderer.resolution * -1)
        : (this.sprite.position.x = window.innerWidth / this.setup.renderer.resolution)

      if (this.index == 1) {
        this.sprite.position.x = window.innerWidth / 4
      } else if (this.index == 2) {
        this.sprite.position.x = window.innerWidth / 2
      } else if (this.index == 3) {
        this.sprite.position.x = (window.innerWidth / 4) * 3
      }
    }
  }

  setPositionY = () => {
    if (!this.setup.isMobile) {
      let gapsAmount
      if (this.index % 2) {
        // right side
        gapsAmount = Math.ceil(this.setup.handsAmount / 2) + 1
      } else {
        // left side
        gapsAmount = Math.floor(this.setup.handsAmount / 2) + 1
      }
      this.sprite.position.y =
        (this.setup.renderer.height / this.setup.renderer.resolution / gapsAmount) *
        Math.round(this.index / 2)
    } else {
      this.sprite.position.y = window.innerHeight + this.sprite.height / 10
      this.sprite.angle = 0
    }

    this.initialPositionY = this.sprite.position.y
  }

  render = (delta, hasGyroscope) => {
    this.lastTime = this.setup.ticker.lastTime
  }
}

export default Hand

import '../styles/main.scss'

import './EventsOverview/EventsOverview'
import './PartnerOverview/PartnerOverview'

import './woocommerce/cart'
import './woocommerce/checkout'

import Hands from './Components/Hands/Hands'

if (module.hot) module.hot.accept()

document.addEventListener('DOMContentLoaded', function DOMContentLoaded() {
  /* eslint-disable */
  let hands

  const loadHands = () => {
    hands = new Hands({
      canvasId: 'handsCanvas',
      canvasWrapperId: 'handsCanvasWrapper',
      useGyroscope: true,
      handsAmount: 4,
      thumbsUpOnClickElementId: 'logo',
      thumbsUpCallback: () => {}
    })
  }

  if (document.getElementsByClassName('nf-form-cont').length) {
    jQuery(document).on('nfFormReady', function () {
      if (!hands) {
        loadHands()
      }
    })
  } else if (document.getElementById('handsCanvasWrapper')) {
    loadHands()
  }
  /* eslint-enable */
})

import Snackbar from 'node-snackbar'

document.addEventListener('DOMContentLoaded', function cartFunctionality() {
  // Function to update the cart counter in the header section of the page
  //----------------------------------------------------------
  function updateCartCountInHeader() {
    const cartCountInHeader = document.getElementById('header-cart-count')
    const items = document.querySelectorAll('.cart-item')
    let total = 0
    items.forEach((item) => {
      const quantity = parseInt(item.dataset.quantity, 10)
      total += quantity
    })
    cartCountInHeader.firstElementChild.innerHTML = total
  }

  // Function to recalculate all prices and updated them in the DOM
  //----------------------------------------------------------
  function updateDOMprices() {
    const items = document.querySelectorAll('.cart-item')
    const shippingCost = parseInt(document.getElementById('shipping').dataset.shippingcost, 10)
    const totalElement = document.getElementById('total-element')
    let total = shippingCost
    items.forEach((item) => {
      const itemTotalElement = item.querySelector('.item-total-element')
      const price = parseInt(item.dataset.price, 10)
      const quantity = parseInt(item.dataset.quantity, 10)
      const itemTotal = parseInt(price * quantity, 10)
      let itemTotalFormat = parseFloat(Math.round(itemTotal * 100) / 100).toFixed(2)
      itemTotalFormat = itemTotalFormat.replace(/\.(00)/, '.&mdash;')
      itemTotalElement.innerHTML = itemTotalFormat
      total += itemTotal
    })
    total = parseFloat(Math.round(total * 100) / 100).toFixed(2)
    totalElement.innerHTML = total.replace(/\.(00)/, '.&mdash;')
  }

  // Function to actually update amount of item in the WooCommerce cart
  //----------------------------------------------------------
  async function updateItemInCart(key, newValue) {
    const data = new FormData()
    data.append('action', 'set_quantity') // WP Ajax action
    data.append('key', key)
    data.append('quantity', newValue)
    const response = await fetch('/wp-admin/admin-ajax.php', {
      method: 'post',
      body: data
    })
    if (response.ok) {
      if (newValue > 0) {
        Snackbar.show({
          text: 'Anzahl geändert.',
          pos: 'bottom-right',
          backgroundColor: '#ffa800',
          showAction: false,
          duration: 30000
        })
      } else {
        Snackbar.show({
          text: 'Das Produkt wurde aus dem Warenkorb entfernt.',
          pos: 'bottom-right',
          backgroundColor: '#ffa800',
          showAction: false,
          duration: 30000
        })
      }
    } else {
      console.error('Fehler beim Ändern der Anzahl.')
      Snackbar.show({
        text: 'Fehler beim Ändern der Anzahl.',
        pos: 'bottom-right',
        backgroundColor: '#ffa800',
        showAction: false,
        duration: 30000
      })
    }
  }

  // Add event listener for all the "delete item"-buttons in the cart
  //----------------------------------------------------------
  const deleteButtons = document.querySelectorAll('.delete-item-cart')
  deleteButtons.forEach((button) => {
    button.addEventListener('click', function deleteButtonFunc(event) {
      event.preventDefault()
      const item = button.closest('.cart-item')
      const { key } = item.dataset
      item.remove()

      updateCartCountInHeader()
      updateDOMprices()
      updateItemInCart(key, 0)
    })
  })

  // Add event listener for all the "set quantity"-buttons in the cart
  //----------------------------------------------------------
  const quantityButtons = document.querySelectorAll('.set-quantity-cart')
  quantityButtons.forEach((button) => {
    button.addEventListener('click', function quantityButtonFunc(event) {
      event.preventDefault()
      const item = button.closest('.cart-item')
      const { key } = item.dataset
      const { type } = button.dataset
      const numberField =
        type === 'decrease' ? button.nextElementSibling : button.previousElementSibling
      const currentValue = parseInt(numberField.innerHTML, 10)

      // Calculate new value
      const newValue = type === 'decrease' ? currentValue - 1 : currentValue + 1
      if (newValue > 0) {
        numberField.innerHTML = newValue
        item.dataset.quantity = newValue

        numberField.classList.add('changed')

        updateCartCountInHeader()
        updateDOMprices()
        updateItemInCart(key, newValue)

        // Reset CSS animation
        setTimeout(function timeoutSetQuantity() {
          numberField.classList.remove('changed')
        }, 200)
      }
    })
  })
})

import React from 'react'
import PropTypes from 'prop-types'

import dayjs from 'dayjs'
import 'dayjs/locale/de'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

import Event from './Event'

dayjs.extend(customParseFormat).extend(isSameOrAfter).locale('de')

const EventsList = (props) => {
  const { events, eventFilters } = props

  const eventsInTheFuture = events.filter((event) =>
    dayjs(event.acf.date, 'YYYY-MM-DD').isSameOrAfter(dayjs(), 'day')
  )

  const eventsSorted = eventsInTheFuture.sort(
    (a, b) => dayjs(a.acf.date, 'YYYY-MM-DD') - dayjs(b.acf.date, 'YYYY-MM-DD')
  )

  const eventsFiltered = eventsSorted.filter(function abc1(event) {
    for (let i = 0; i < eventFilters.length; i += 1) {
      if (event['event-category'].indexOf(eventFilters[i]) > -1) {
        return true
      }
    }
    return false
  })

  const eventsToDisplay = eventFilters.length > 0 ? eventsFiltered : eventsSorted

  return (
    <>
      {eventsToDisplay.map((event) => (
        <Event key={event.id} event={event} className='event' />
      ))}
    </>
  )
}

EventsList.propTypes = {
  events: PropTypes.array.isRequired,
  eventFilters: PropTypes.array.isRequired
}

export default EventsList

import React from 'react'

const Loader = () => {
  return (
    <div className='bouncing-loader loader--yellow'>
      <div className='bouncing-loader__dot' />
      <div className='bouncing-loader__dot' />
      <div className='bouncing-loader__dot' />
    </div>
  )
}

export default Loader

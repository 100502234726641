import * as PIXI from 'pixi.js'
import Hand from './Components/Hand'
/* eslint-disable */
Array.prototype.shuffle = function () {
  return this.sort(function () {
    return Math.random() - 0.5
  })
}

Array.prototype.getRandomValue = function () {
  return this.shuffle()[0]
}

Array.prototype.getUniqueValues = function () {
  return [...new Set(this)]
}

const debug = false

const setup = {
  debug,
  vmin: window.innerWidth > window.innerHeight ? window.innerHeight / 100 : window.innerWidth / 100,
  vh: window.innerHeight / 100,
  vw: window.innerWidth / 100,
  windowRatio: window.innerWidth / window.innerHeight,
  designWidth: 1760,
  BS: window.innerWidth / 1760,
  renderer: null,
  loader: null,
  getAngleBetweenPoints: (p1, p2) => {
    return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI
  },
  getRotationBetweenPoints: (p1, p2) => {
    return Math.atan2(p2.y - p1.y, p2.x - p1.x)
  },
  getDistanceBetweenPoints: (p1, p2) => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
  },
  bringToFront: (obj) => {
    if (obj) {
      const { parent } = obj
      if (parent) {
        parent.removeChild(obj)
        parent.addChild(obj)
      }
    }
  },
  moveToPosition: (object, distance, rotation, initSpeed) => {
    let speed = initSpeed
    if (!speed) {
      speed = 5
    }
    speed /= 100

    object.x += distance * Math.cos(rotation) * speed
    object.y += distance * Math.sin(rotation) * speed
  },
  sendToBack: (obj, parent) => {
    const parentObj = parent || obj.parent || { children: false }
    if (parentObj.children) {
      for (const keyIndex in obj.parent.children) {
        if (obj.parent.children[keyIndex] === obj) {
          obj.parent.children.splice(keyIndex, 1)
          break
        }
      }
      parentObj.children.splice(0, 0, obj)
    }
  },
  debugLog: (str) => {
    if (debug) {
      console.log(str)
    }
  }
}

class Hands {
  constructor(data) {
    this.canvas = document.getElementById(data.canvasId)
    this.wrapper = document.getElementById(data.canvasWrapperId)
    this.thumbsUpTriggerElement = data.thumbsUpOnClickElementId
      ? document.getElementById(data.thumbsUpOnClickElementId)
      : null
    this.thumbsUpCallback = data.thumbsUpCallback ? data.thumbsUpCallback : null
    this.useGyroscope = data.useGyroscope

    this.mobileBreakpoint = data.mobileBreakpoint
    this.checkIsMobile()

    setup.renderer = new PIXI.Renderer({
      view: this.canvas,
      width: window.innerWidth,
      height: this.wrapper ? this.wrapper.getBoundingClientRect().height : window.innerHeight,
      resolution: window.devicePixelRatio,
      autoDensity: true,
      transparent: true
    })
    setup.stage = new PIXI.Container()
    setup.loader = PIXI.Loader.shared
    setup.loader = new PIXI.Loader()

    setup.handsAmount = data.handsAmount ? data.handsAmount : 4
    this.hands = []
    this.preloadAssets()
    this.onResize()
  }

  bindEvents = () => {
    window.addEventListener('resize', this.onResize)

    if (this.thumbsUpTriggerElement) {
      this.thumbsUpTriggerElement.addEventListener('click', this.onThumbsUpTrigger)
    }

    if (this.wrapper) {
      this.wrapper.addEventListener('mousemove', this.onMouseMove)
    } else {
      window.addEventListener('mousemove', this.onMouseMove)
    }

    if (this.useGyroscope) {
      window.addEventListener('click', this.checkHasGyroscope)
    }
  }

  checkIsMobile = () => {
    let { mobileBreakpoint } = this
    if (!mobileBreakpoint && setup.debug) {
      mobileBreakpoint = 768
    }

    if (mobileBreakpoint) {
      setup.isMobile = window.innerWidth <= mobileBreakpoint
    } else {
      setup.isMobile =
        typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1
    }
  }

  checkHasGyroscope = (e) => {
    this.hasGyroscope = !!(
      (typeof DeviceOrientationEvent !== 'undefined' && DeviceOrientationEvent.requestPermission) ||
      navigator.userAgent.match('CriOS') ||
      navigator.userAgent.match('iPhone')
    )

    if (this.hasGyroscope) {
      DeviceMotionEvent.requestPermission()
        .then((response) => {
          if (response == 'granted') {
            if (window.DeviceOrientationEvent) {
              window.addEventListener(
                'deviceorientation',
                (e) => {
                  this.setHandGyroOrientation(e.alpha)
                  // alpha between 80 - 280 is visible
                },
                true
              )
            } else if (window.DeviceMotionEvent) {
              window.addEventListener(
                'devicemotion',
                (e) => {
                  this.setHandGyroOrientation(e.acceleration.z)
                },
                true
              )
            }
          }
        })
        .catch(console.error)
    }
  }

  setHandGyroOrientation = (angle) => {
    this.hands.forEach((hand) => {
      hand.sprite.angle = angle * -1
    })
  }

  onThumbsUpTrigger = () => {
    const stackTime = 100
    const minUpTime = 400

    this.hands.forEach((hand) => {
      if (hand.thumbTexture) {
        window.setTimeout(() => {
          hand.sprite.texture = hand.thumbTexture

          window.setTimeout(() => {
            hand.sprite.texture = hand.pointerTexture
          }, Math.random() * 400 + minUpTime)
        }, Math.random() * stackTime)
      }
    })

    if (this.thumbsUpCallback) {
      this.thumbsUpCallback()
    }
  }

  onResize = () => {
    setup.debugLog('triggered onResize')
    this.checkIsMobile()
    setup.renderer.resize(
      window.innerWidth,
      this.wrapper ? this.wrapper.getBoundingClientRect().height : window.innerHeight
    )
    setup.BS = window.innerWidth / 1760

    this.hands.forEach((hand) => {
      hand.setPositionX()
      hand.setPositionY()
      hand.setScale()
    })
  }

  onMouseMove = (e) => {
    this.currentMousePos = { clientX: e.clientX, clientY: e.clientY }
    if (!this.hasGyroscope) {
      this.hands.forEach((hand) => {
        const angle =
          setup.getAngleBetweenPoints(
            {
              x: hand.sprite.position.x,
              y: this.canvas.offsetTop - window.scrollY + hand.sprite.position.y
            },
            { x: e.clientX, y: e.clientY }
          ) + 90

        if (
          (angle - 90 >= hand.rotationBoundary * -1 && angle - 90 <= hand.rotationBoundary) ||
          angle - 90 >= 180 - hand.rotationBoundary ||
          angle - 90 <= -180 + hand.rotationBoundary
        ) {
          hand.sprite.angle = angle
        }
      })
    }
  }

  preloadReady = () => {
    const handTypes = Object.keys(setup.loader.resources).filter((handKey) => {
      if (handKey.indexOf('Pointer') !== -1) {
        return true
      }
      return false
    })

    for (let i = 1; i <= setup.handsAmount; i++) {
      handTypes.shuffle()
      const hand = new Hand(setup, handTypes.shift(), i)
      this.hands.push(hand)

      setup.stage.addChild(hand.pixiObj)
    }

    this.ticker = new PIXI.Ticker()
    setup.ticker = this.ticker
    this.ticker.add(this.render)

    this.bindEvents()

    this.ticker.start()
  }

  preloadAssets = () => {
    const path = '../wp-content/uploads/hands'

    setup.loader
      .add('hand_1_Pointer', path + '/hand_1_pointer.png')
      .add('hand_1_Thumb', path + '/hand_1_thumb.png')
      .add('hand_2_Pointer', path + '/hand_2_pointer.png')
      .add('hand_2_Thumb', path + '/hand_2_thumb.png')
      .add('hand_3_Pointer', path + '/hand_3_pointer.png')
      .add('hand_3_Thumb', path + '/hand_3_thumb.png')
      .add('hand_4_Pointer', path + '/hand_4_pointer.png')
      .add('hand_4_Thumb', path + '/hand_4_thumb.png')
      .add('hand_5_Pointer', path + '/hand_5_pointer.png')
      .load()

    // throughout the process multiple signals can be dispatched.
    setup.loader.onProgress.add(() => {}) // called once per loaded/errored file
    setup.loader.onError.add((e) => {
      console.log('error: ' + e)
    }) // called once per errored file
    setup.loader.onLoad.add((a, resource, c) => {
      setup.debugLog(`Done Loading: ${resource.name}`)
    }) // called once per loaded file
    setup.loader.onComplete.add(this.preloadReady) // called once when the queued resources all load.
  }

  render = (delta) => {
    this.hands.forEach((hand) => {
      hand.render(delta, this.hasGyroscope)
    })

    if (this.currentMousePos) {
      this.onMouseMove(this.currentMousePos)
    }

    setup.renderer.render(setup.stage)
  }
}

export default Hands
